// ThemeFab.js
import React from 'react';
import { Fab } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useTheme } from './ThemeContext';

const ThemeFab = () => {
    const { theme, toggleTheme } = useTheme();

    return (
        <Fab
            aria-label="toggle theme"
            onClick={toggleTheme}
            style={{
                position: 'fixed', // Position fixed to float
                top: 20, // Top margin
                right: 20, // Right margin
                zIndex: 1000, // Ensure it's above other elements
            }}>
            {theme === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
        </Fab>
    );
};

export default ThemeFab;
