import React, { useState } from 'react';
import { Container, Grid, Card, CardMedia, CardContent, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import './Music.css'; // Import the CSS file

const Music = ({ releases, onReleaseSelect }) => {
    const theme = useTheme();
    const [activeReleaseIndex, setActiveReleaseIndex] = useState(null);
    const audioUrlMP3 = localStorage.getItem('audioUrlMP3');

    const handleReleaseClick = (release, index) => {
        setActiveReleaseIndex(index);
        onReleaseSelect(release);

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const renderReleaseButtons = () => {
        return releases.map((release, index) => {
            let imageUrl = release.imageUrl;

            if (release.platform === "Unreleased") {
                imageUrl = process.env.PUBLIC_URL + '/Unreleased.png';
                if (audioUrlMP3) {
                    const releaseTitle = release.title.replace(/\s+/g, '-');
                    imageUrl = process.env.PUBLIC_URL + `/${releaseTitle}.png`;
                }
            }

            return (
                <Grid item key={index} xs={6} sm={4} md={3} lg={3}>
                    <Card 
                        onClick={() => handleReleaseClick(release, index)}
                        className={`card ${activeReleaseIndex === index ? 'card-active' : ''}`}
                        sx={{
                            width: '100%',
                            height: {xs: '120px', sm: '250px'},
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <CardMedia
                            component="img"
                            image={imageUrl}
                            alt={release.title}
                            sx={{
                                height: {xs: '50px', sm: '155px'}
                            }}
                        />
                        <CardContent style={{ backgroundColor: theme.palette.mode === 'dark' ? '#000000' : '#CCCCCC', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography 
                                gutterBottom 
                                variant="body2" 
                                align="center"
                                className="truncate-text"
                                sx={{
                                    fontSize: { xs: '0.7rem', sm: '0.8rem' },
                                    width: '100%'
                                }}
                            >
                                {release.title}
                            </Typography>
                            <Typography 
                                variant="caption" 
                                color="text.secondary"
                                sx={{
                                    fontSize: { xs: '0.5rem', sm: '0.6rem' },
                                }}
                            >
                                {release.releaseType}
                            </Typography>
                            <Typography 
                                variant="caption" 
                                color="text.secondary"
                                sx={{
                                    fontSize: { xs: '0.5rem', sm: '0.7rem' },
                                }}
                            >
                                Released: {release.releaseDate}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            );
        });
    };

    return (
        <Container style={{ 
            marginTop: '10px', 
            marginBottom: '25px', 
            display: 'flex', 
            justifyContent: 'center' 
        }}>
            <Grid container spacing={4} style={{ 
                maxWidth: '100%', 
                display: 'flex', 
                justifyContent: 'center' 
            }}>
                {renderReleaseButtons()}
            </Grid>
        </Container>
    );
}

export default Music;
