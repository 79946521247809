import React from 'react';
import { Container, IconButton, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

import SpotifyDarkSVG from '../assets/spotify-dark.svg';
import SpotifyLightSVG from '../assets/spotify-light.svg';
import AppleMusicDarkSVG from '../assets/applemusic-dark.svg';
import AppleMusicLightSVG from '../assets/applemusic-light.svg';
import InstagramDarkSVG from '../assets/instagram-dark.svg';
import InstagramLightSVG from '../assets/instagram-light.svg';
import TikTokDarkSVG from '../assets/tiktok-dark.svg';
import TikTokLightSVG from '../assets/tiktok-light.svg';
import TwitterXDarkSVG from '../assets/twitterx-dark.svg';
import TwitterXLightSVG from '../assets/twitterx-light.svg';
import FacebookDarkSVG from '../assets/facebook-dark.svg';
import FacebookLightSVG from '../assets/facebook-light.svg';
import YouTubeDarkSVG from '../assets/youtube-dark.svg';
import YouTubeLightSVG from '../assets/youtube-light.svg';
import DiscordDarkSVG from '../assets/discord-dark.svg';
import DiscordLightSVG from '../assets/discord-light.svg';

const NavBar = () => {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const logoWidth = window.innerWidth <= 768 ? 105 : 135;

    const socialLinks = [
        { iconDark: SpotifyDarkSVG, iconLight: SpotifyLightSVG, url: 'https://open.spotify.com/artist/1X1vnHROFhPfDxqhjLmSFw' },
        { iconDark: AppleMusicDarkSVG, iconLight: AppleMusicLightSVG, url: 'https://music.apple.com/us/artist/eli/1719384311' },
        { iconDark: InstagramDarkSVG, iconLight: InstagramLightSVG, url: 'https://www.instagram.com/real1elias' },
        { iconDark: TikTokDarkSVG, iconLight: TikTokLightSVG, url: 'https://www.tiktok.com/@real1eli' },
        { iconDark: TwitterXDarkSVG, iconLight: TwitterXLightSVG, url: 'https://www.twitter.com/real1eli' },
        { iconDark: FacebookDarkSVG, iconLight: FacebookLightSVG, url: 'https://www.facebook.com/real1elias' },
        { iconDark: YouTubeDarkSVG, iconLight: YouTubeLightSVG, url: 'https://www.youtube.com/@real1elias' },
        { iconDark: DiscordDarkSVG, iconLight: DiscordLightSVG, url: 'https://discord.gg/REj3hyWbTF' },
    ];

    return (
        <Container style={{ backgroundColor: theme.palette.background.default }}>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                <Link to="/">
                    <img src={process.env.PUBLIC_URL + (isDarkMode ? "/WhiteKnowledgeLogo.png" : "/BlackKnowledgeLogo.png")} alt="Logo" width={logoWidth} height="" /> {/* logo png image ratio is 1 to 0.71 */}
                </Link>
            </div>
            <Box textAlign={"center"} mt={0.5}>
                {socialLinks.map((link, index) => (
                    <IconButton
                        key={index}
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                            backgroundColor: 'transparent',
                            borderRadius: '75%',
                            margin: { xs: '0 3px', sm: '0 3px' },
                            '&:hover': {
                                backgroundColor: 'transparent',
                                transform: 'scale(1.5)',
                            },
                            height: { xs: '36px', sm: '42px' },
                            width: { xs: '36px', sm: '42px' },
                            padding: { xs: '2px', sm: '3px' },
                            transition: 'transform 0.1s ease-in-out',
                        }}
                    >
                        <img src={isDarkMode ? link.iconLight : link.iconDark} alt={`${link.url} icon`} style={{ width: '100%', height: '100%' }} />
                    </IconButton>
                ))}
            </Box>
        </Container>
    );
}

export default NavBar;
