import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Container, Typography, Button, Box, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Contact = () => {
    const theme = useTheme();

    return (
        <Container style={{ marginTop: '25px', marginBottom: '25px' }}>
            <Typography align='center' variant="h4" gutterBottom>
                Contact Info
            </Typography>
            <Box textAlign={"center"} mt={4}>
                <Typography variant="h6">
                    You can get in touch with me via social media or the email address below.
                </Typography>
                <Typography variant="h6">
                    Serious inquiries only please.
                </Typography>
                <Typography variant="h6" mt={5}>
                    Email: {' '}
                    <Link 
                        href="mailto:contact@elimusic.art" 
                        sx={{
                            color: theme.palette.text.primary,
                            textDecoration: 'none',
                            '&:hover': {
                                textDecoration: 'underline',
                                color: theme.palette.primary.dark,
                            }
                        }}
                    >
                        contact@elimusic.art
                    </Link>
                </Typography>
            </Box>
            <Box textAlign={"center"} mt={8} marginBottom={5}>
                <Button
                    variant="outlined"
                    component={RouterLink}
                    to="/"
                >
                    Back to Home
                </Button>
            </Box>
        </Container>
    );
};

export default Contact;
