// ShareFab.js
import React, { useState } from 'react';
import { Fab, Snackbar } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';

const ShareFab = () => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleShareClick = () => {
        navigator.clipboard.writeText(window.location.href)
            .then(() => setSnackbarOpen(true))
            .catch(err => console.error('Could not copy text: ', err));
    };

    return (
        <>
            <Fab
                aria-label="share"
                onClick={handleShareClick}
                style={{
                    position: 'fixed',
                    top: 20,
                    left: 20,
                    zIndex: 1000,
                }}
            >
                <ShareIcon />
            </Fab>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                message="URL copied to clipboard"
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            />
        </>
    );
};

export default ShareFab;
