import React from 'react';
import { Container, Typography, IconButton, Button, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import SpotifyDarkSVG from '../assets/spotify-dark.svg';
import SpotifyLightSVG from '../assets/spotify-light.svg';
import AppleMusicDarkSVG from '../assets/applemusic-dark.svg';
import AppleMusicLightSVG from '../assets/applemusic-light.svg';
import InstagramDarkSVG from '../assets/instagram-dark.svg';
import InstagramLightSVG from '../assets/instagram-light.svg';
import TikTokDarkSVG from '../assets/tiktok-dark.svg';
import TikTokLightSVG from '../assets/tiktok-light.svg';
import TwitterXDarkSVG from '../assets/twitterx-dark.svg';
import TwitterXLightSVG from '../assets/twitterx-light.svg';
import FacebookDarkSVG from '../assets/facebook-dark.svg';
import FacebookLightSVG from '../assets/facebook-light.svg';
import YouTubeDarkSVG from '../assets/youtube-dark.svg';
import YouTubeLightSVG from '../assets/youtube-light.svg';
import DiscordDarkSVG from '../assets/discord-dark.svg';
import DiscordLightSVG from '../assets/discord-light.svg';
import SnapChatDarkSVG from '../assets/snapchat-dark.svg';
import SnapChatLightSVG from '../assets/snapchat-light.svg';
import SoundCloudDarkSVG from '../assets/soundcloud-dark.svg';
import SoundCloudLightSVG from '../assets/soundcloud-light.svg';
import NewDarkSVG from '../assets/new-dark.svg';
import NewLightSVG from '../assets/new-light.svg';

import './Links.css';

const links = [
    { label: 'New Song', url: 'https://social.tunecore.com/linkShare?linkid=BMtqsJ_AD69pqnGbGGL81w', iconDark: NewDarkSVG, iconLight: NewLightSVG, width: '30px', height: '30px', margin: '5px' },
    { label: 'My Entire Music Catalog', url: 'https://www.elimusic.art', iconDark: process.env.PUBLIC_URL + ("/BlackKnowledgeLogo.png"), iconLight: process.env.PUBLIC_URL + ("/WhiteKnowledgeLogo.png"), width: '42px', height: '30px', margin: '0px' },
    { label: 'TikTok', url: 'https://www.tiktok.com/@real1eli', iconDark: TikTokDarkSVG, iconLight: TikTokLightSVG, width: '30px', height: '30px', margin: '5px' },
    { label: 'Instagram', url: 'https://www.instagram.com/real1elias', iconDark: InstagramDarkSVG, iconLight: InstagramLightSVG, width: '30px', height: '30px', margin: '5px' },
    { label: 'YouTube', url: 'https://www.youtube.com/@real1elias', iconDark: YouTubeDarkSVG, iconLight: YouTubeLightSVG, width: '30px', height: '30px', margin: '5px' },
    { label: 'My Discord Server', url: 'https://discord.gg/REj3hyWbTF', iconDark: DiscordDarkSVG, iconLight: DiscordLightSVG, width: '30px', height: '30px', margin: '5px' },
];

const musicLinks = [
    { iconDark: SpotifyDarkSVG, iconLight: SpotifyLightSVG, url: 'https://open.spotify.com/artist/1X1vnHROFhPfDxqhjLmSFw' },
    { iconDark: AppleMusicDarkSVG, iconLight: AppleMusicLightSVG, url: 'https://music.apple.com/us/artist/eli/1719384311' },
    { iconDark: SoundCloudDarkSVG, iconLight: SoundCloudLightSVG, url: 'https://soundcloud.com/real1eli' },
    { iconDark: SnapChatDarkSVG, iconLight: SnapChatLightSVG, url: 'https://www.snapchat.com/add/realeliasgarcia' },
    { iconDark: FacebookDarkSVG, iconLight: FacebookLightSVG, url: 'https://www.facebook.com/real1elias' },
    { iconDark: TwitterXDarkSVG, iconLight: TwitterXLightSVG, url: 'https://www.twitter.com/real1eli' },
];

const Links = () => {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const backgroundColor = isDarkMode ? '#FFFFFF' : '#000000';
    const textColor = isDarkMode ? '#000000' : '#FFFFFF';

    return (
        <Container style={{ marginTop: '20px', marginBottom: '25px', textAlign: 'center' }}>
            <Box
                textAlign={"center"}
                component="img"
                src={process.env.PUBLIC_URL + ("/profile.JPG")}
                alt="Profile Image"
                sx={{
                    width: { xs: '96px', sm: '125px' },
                    height: { xs: '96px', sm: '125px' },
                    borderRadius: '50%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                }}
            />
            <Typography variant="h6" mt={1}>
                Elias
            </Typography>
            <Box textAlign={"center"} mt={0.5}>
                {links.map((link, index) => (
                    <Button
                        key={index}
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        variant="contained"
                        className={index === 0 ? 'animate-button' : ''}
                        sx={{
                            width: '100%',
                            maxWidth: '580px',
                            margin: '10px 0',
                            padding: '10px',
                            borderRadius: '12px',
                            backgroundColor: backgroundColor,
                            color: textColor,
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            transition: 'transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out',
                            '&:hover': {
                                backgroundColor: backgroundColor,
                                transform: 'scale(1.05)',
                                boxShadow: '0 6px 8px rgba(0, 0, 0, 0.15)',
                            },
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                width: '100%',
                            }}
                        >
                            <img src={isDarkMode ? link.iconDark : link.iconLight} alt={`${link.label} icon`} style={{ width: link.width, height: link.height, marginLeft: link.margin }} />
                            <span style={{ flexGrow: 1, textAlign: 'center' }}>{link.label}</span>
                            <img src={isDarkMode ? link.iconDark : link.iconLight} alt={`${link.label} icon`} style={{ width: link.width, height: link.height, marginRight: link.margin, visibility: 'hidden' }} />
                        </Box>
                    </Button>
                ))}
            </Box>
            <Box textAlign={"center"} mt={2} marginBottom={5}>
                {musicLinks.map((link, index) => (
                    <IconButton
                        key={index}
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                            backgroundColor: 'transparent',
                            borderRadius: '75%',
                            margin: { xs: '0 3px', sm: '0 3px' },
                            '&:hover': {
                                backgroundColor: 'transparent',
                                transform: 'scale(1.5)',
                            },
                            height: { xs: '36px', sm: '42px' },
                            width: { xs: '36px', sm: '42px' },
                            padding: { xs: '2px', sm: '3px' },
                            transition: 'transform 0.1s ease-in-out',
                        }}
                    >
                        <img src={isDarkMode ? link.iconLight : link.iconDark} alt={`${link.url} icon`} style={{ width: '100%', height: '100%' }} />
                    </IconButton>
                ))}
            </Box>
        </Container>
    );
};

export default Links;
