import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { useTheme } from './components/ThemeContext';
import ShareFab from './components/ShareFab';
import ThemeFab from './components/ThemeFab';
import MainPage from './components/MainPage';
import NavBar from './components/NavBar';
import Success from './components/Success';
import Links from './components/Links';
import Contact from './components/Contact';
import Footer from './components/Footer';
import './App.css';
import 'aos/dist/aos.css';
import AOS from 'aos';

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
        AOS.init({
            duration: 1000,
        });
    }, [pathname]);

    return null;
}

function App() {
    const { theme } = useTheme();
    const location = useLocation();

    const muiTheme = createTheme({
        palette: {
            mode: theme, // Use context theme here
            // ... other theme settings ...
        },
        typography: {
            fontFamily: 'Custom-Arvo, sans-serif',
        },
    });

    useEffect(() => {
        document.body.style.backgroundColor = muiTheme.palette.background.default;
        document.body.style.color = muiTheme.palette.text.primary;

    }, [muiTheme]);

    return (
        <MuiThemeProvider theme={muiTheme}>
            <ScrollToTop />
            {location.pathname !== '/links' && <NavBar />} {/* Conditionally render NavBar */}
            <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/success" element={<Success />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/links" element={<Links />} />
            </Routes>
            <Footer />
            <ThemeFab />
            <ShareFab />
        </MuiThemeProvider>
    );
}

function AppWrapper() {
    return (
        <Router basename="/">
            <App />
        </Router>
    );
}

export default AppWrapper;
